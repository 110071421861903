export const filtrarCategoriasPorProducto = (productos) => {
  // Si productos es undefined o null, retornar un array vacío
  if (!productos || !Array.isArray(productos)) {
    return [];
  }

  const categoriasUnicas = new Set();
  
  productos.forEach(producto => {
    if (producto.categoria) {
      categoriasUnicas.add(producto.categoria);
    }
  });

  return Array.from(categoriasUnicas);
}; 