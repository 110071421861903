import React, {useEffect, useState} from 'react';
import '../Carta.css';
import iconoGenerico from '../iconoGenerico.svg';
import iconoFooter from '../logoFooter.svg';
import {
  AddAlt,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  Search,
  ShoppingCart,
  SubtractAlt,
  TrashCan
} from '@carbon/icons-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {useConfig} from "../context/ConfigContext";
import { filtrarCategoriasPorProducto } from '../utils/filterUtils';

export default function Carta() {

  const [categories, setCategories] = useState([]);
  const [shop, setShop] = useState();

  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');

  const [imageSrc, setImageSrc] = useState('');
  const [colorshop, setColorShop] = useState('#000000');
  const [nameShop, setNameShop] = useState('');
  const [nameCategorySelected, setNameCategorySelected] = useState('');

  const [productsForCategory, setProductsForCategory] = useState([]);

  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCategoriesWithProducts, setSearchCategoriesWithProducts] = useState([]);
  const [searching, setSearching] = useState(false);

  const [allProducts, setAllProducts] = useState(true);
  const allProductsText = "TODOS LOS PRODUCTOS";

  const [dataProductsPedidoModal, setDataProductsPedidoModal] = useState([]);
  const [observationsPedido, setObservationsPedido] = useState('');

  //datos de envio
  const [nameAndSuername, setNameAndSuername] = useState('');
  const [isDelivery, setIsDelivery] = useState(false);
  const [streetAddress, setStreetAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [localidadDirection, setLocalidadDirection] = useState('');
  const [floorAddress, setFloorAddress] = useState('');
  const [deptoAddress, setDeptoAddress] = useState('');
  const [observationsAddress, setObservationsAddress] = useState('');
  const [imagesBanners, setImagesBanners] = useState([]);

  // Estados de validaciones activas/inactivas
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [lastValidatedAddress, setLastValidatedAddress] = useState({});

  //Estados config
  const {
    config
  } = useConfig();

  // chequea si tiene habilitada la feature de negocio GEOLOCALIZACION
  const { features } = useConfig();
  const googleLocalizationFeature = features.find(
      (feature) => feature.name === 'GEOLOCALIZACION' && feature.status === true
  );
  const ruralSettingsFeatures = features.find(feature => feature.name === 'ZONA RURAL');
  const [shippingPrice, setShippingPrice] = useState(0.0);
  // fin chequea si tiene habilitada la feature de negocio GEOLOCALIZACION

  const [isRuralDelivery, setIsRuralDelivery] = useState(false);

  //el precio base de los delivery
  function getDeliveryPriceBase(config, isRuralDelivery) {
    // Verificar si la entrega es rural
    if (isRuralDelivery) {
      return config.priceRuralBase || 0;
    }

    // Si no es rural, revisa el tipo de entrega
    switch (config.deliveryType) {
      case 'COMMON':
        return config.deliveryCommon?.priceBase || 0;
      case 'CUSTOM':
        return shippingPrice;
      default:
        return 0;
    }
  }

  const toggleRuralDelivery = (isChecked) => {
    setIsRuralDelivery(isChecked);
    if (isChecked) {
      setShippingPrice(config.priceRuralBase);
      cleanErrorSendPedidoWithoutDeliveryReset();
    } else {
      const newPrice = formatPrice(getDeliveryPriceBase(config));
      setShippingPrice(newPrice);
    }
  };


  // Detecta si la dirección ha cambiado después de la validación
  const checkAddressChange = () => {
    const hasChanged =
        streetAddress !== lastValidatedAddress.streetAddress ||
        numberAddress !== lastValidatedAddress.numberAddress ||
        localidadDirection !== lastValidatedAddress.localidadDirection;

    if (hasChanged) {
      setIsAddressValid(false);
    }
  };

  const showAlert = ({ title, text, icon, confirmButton = true }) => {
    Swal.fire({
      title,
      text,
      icon,
      showConfirmButton: confirmButton,
      confirmButtonColor: colorshop,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const getLogoImage = () => {
    // Obtener Logo de catálogo Carta
    axios.get(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_LOGO_IMAGE + "/1", {
      responseType: "blob",
    })
      .then((response) => {
        console.warn(response)
        if(response.data.size !== 0){
          const imageBlob = response.data;
          const imageURL = URL.createObjectURL(imageBlob);
          setImageSrc(imageURL);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error cargando la imagen del logo',
        })
      })
  }

  const getCategoryColor = () => {
    axios.get(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_CATEGORY_COLOR + "/1")
      .then((response) => {
        setColorShop(response.data);
      })
      .catch((error) => {console.log(error)})
  }

  useEffect(() => {
    document.title = 'CARTA';

    if(!localStorage.getItem("productsPedido")){
      const listadoVacio = [];
      const listadoVacioJSON = JSON.stringify(listadoVacio);
      localStorage.setItem("productsPedido", listadoVacioJSON);
    }
    getAllCategories();
    getShop();
    getRedes();
    getAllImagesBanners();
    getLogoImage();
    getCategoryColor();
    validateAddress();

}, [config]);

  const validateAddress = async () => {
    const localId = 1;
    const baseUrl = process.env.REACT_APP_API_LOCALIZATION_VALIDATE_ROUTE;

    const address = `${streetAddress} ${numberAddress}`;
    const encodedAddress = encodeURIComponent(address);
    const encodedLocality = encodeURIComponent(localidadDirection);
    if (streetAddress && numberAddress && localidadDirection) {
      try {
        const response = await fetch(`${baseUrl}${localId}?address=${encodedAddress}&locality=${encodedLocality}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-Geolocation-Key': process.env.REACT_APP_API_KEY_GEOLOCATION,
          }
        });

        if (response.ok) {
          const data = await response.json();
          const { valid, message, shippingPrice, isOutOfRange } = data;

          if (valid === true) {
            showAlert({title: 'Dirección validada', text: `La dirección ha sido validada correctamente. 
            Precio de envío: ${formatPrice(shippingPrice) || 'N/A'}`, icon: 'success',});
            setShippingPrice(shippingPrice);
            setIsAddressValid(true);
            setLastValidatedAddress({
              streetAddress,
              numberAddress,
              localidadDirection,
            });
          } else {
            showAlert({title: 'Error de validación:', text: message, icon: 'error',});
            setIsAddressValid(false);
            if (isOutOfRange) {
              showAlert({title: 'Error de validación:', text: message, icon: 'error',});
            }
          }
        }
      } catch (error) {
        showAlert({title: 'Error de conexión:', text: 'Hubo un problema al validar la dirección.', icon: 'error',});
        setIsAddressValid(false);
        throw error;
      }
      }
  };

  const SendOrderType = {
      A_DOMICILIO: { label: "A domicilio", value: 1 },
      RETIRO_LOCAL: { label: "Retira en local", value: 2 }
    };
    const OrderType = {
      WHATSAP: { label: "WhatsApp", value: 1 },
      EN_MESA: { label: "En Mesa", value: 2 },
      DELIVERY: { label: "Delivery", value: 3 },
      A_DISTANCIA: { label: "A Distancia", value: 4 }
    };

  const getAllImagesBanners = () => {
    axios.get(process.env.REACT_APP_API_BANNER_IMAGES_GET_ROUTE).then(response => {
      setImagesBanners(response.data);
    })
  }

  const getAllCategories = () => {
    axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE_CACHED)
    .then(response => {
        setCategories(response.data);
        setNameCategorySelected(allProductsText);
      })
      .catch(error => {
        console.error(error)});
  }

  const getShop = () => {
    axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1')
    .then(response => {
        const shopData = response.data;
        setShop(shopData);
        setNameShop(shopData.businessName);
      })
      .catch(error => {
        console.error(error)});
  }

  const getRedes = () => {
    axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
        params: {
            status: true,
        }
      })
      .then(response => {
          getValueRedInstagram(response.data);
          getValueRedFacebook(response.data);
          getValueRedTwitter(response.data);
      })
  }

  const getValueRedInstagram = (redes) => {
    let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
    if(instagram != null){
        setInstagram(instagram.socialMediaLink);
    }
  }

  const getValueRedFacebook = (redes) =>{
      let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
      if(facebook != null){
          setFacebook(facebook.socialMediaLink);
      }
  }

  const getValueRedTwitter = (redes) =>{
      let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
      if(twitter != null){
          setTwitter(twitter.socialMediaLink);
      }
  }

  const fillProductsByIdCategorySelected = (idCategory) => {
    setProductsForCategory([]);  // Inicializar con array vacío
    document.getElementById('nameProdSearch').value = "";
    search('');
    const categoria = categories.find(categoria => categoria.id === idCategory);
    setProductsForCategory(categoria ? categoria.productDTOList : []);
  }

  const fillAllProducts = () => {
    setProductsForCategory([]);  // Inicializar con array vacío
    document.getElementById('nameProdSearch').value = "";
    search('');
  }

  const search = (searchTerm) => {
    if (searchTerm) {
      setSearching(true);
      
      if (allProducts) {
        // Búsqueda en todas las categorías
        const productosFiltrados = categories.flatMap(category => 
          (category.productDTOList || []).filter(product =>
            (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
        
        // Agrupar productos por categoría
        const categoriasFiltradas = categories.map(category => ({
          ...category,
          productDTOList: (category.productDTOList || []).filter(product =>
            (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase()))
        })).filter(category => category.productDTOList.length > 0);

        setSearchProducts(productosFiltrados);
        setSearchCategoriesWithProducts(categoriasFiltradas);
      } else {
        // Búsqueda en la categoría actual
        const productosFiltrados = (productsForCategory || []).filter(product =>
          (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchProducts(productosFiltrados);
      }
    } else {
      setSearching(false);
      setSearchProducts([]);
      setSearchCategoriesWithProducts([]);
    }
  };

  function formatPrice(price) {
    if (price == null || isNaN(price)) return '';
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(price);
  }

  const addProdPedido = (newProd) => {
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === newProd.id) == -1;
      if (notExistProduct) {
        let newProduct = {
          id: newProd.id,
          name: newProd.name,
          description: newProd.description,
          price: newProd.price,
          cant: 1
        }
        productsPedido.push(newProduct);
      } else {
        const indiceProducto = productsPedido.findIndex(product => product.id === newProd.id);
        productsPedido[indiceProducto].cant += 1;
      }
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));

      const cantEnPedidoElement = document.getElementById('cantEnPedido');
      if (cantEnPedidoElement) {
        cantEnPedidoElement.value = parseInt(cantEnPedidoElement.value || 0) + 1;
      } else {
        console.warn("Element with ID 'cantEnPedido' not found.");
      }
  }

  const minusProdPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
      if(!notExistProduct){
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        if(productsPedido[indiceProducto].cant > 1){
          productsPedido[indiceProducto].cant -= 1;
        }else{
          productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
        }
        localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
        setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
        document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value - 1;
      }
    }
  }

  const deleteProductPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
    }
  }

  const contProd = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      if(productsPedido != null){
        const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
        if(notExistProduct){
          return "0";
        }
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        return productsPedido[indiceProducto].cant.toString();
      }
      return "0";
    }
    return "0";
  }

  const calcularTotal = (productos) => {
    let total = 0;
  
    for (let i = 0; i < productos.length; i++) {
      const producto = productos[i];
      const subtotal = producto.price * producto.cant;
      total += subtotal;
    }
  
    return total;
  }

  const cleanPedidoBorrador = () => {
    Swal.fire({
      title: '¿Estás seguro de borrar el pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cleanPedido();
      } else {
        const botonCarrito = document.getElementById('btnCarrito');
        botonCarrito.click();
      }
    });
  }

  const cleanPedido = () => {
    document.getElementById('cantEnPedido').value = "0";
    const listadoVacio = [];
    const listadoVacioJSON = JSON.stringify(listadoVacio);
    localStorage.setItem("productsPedido", listadoVacioJSON);
    window.location.reload();
  }

  // Función reutilizable para mostrar errores de campo
  const showFieldError = (selectorError, selectorInput) => {
    const fieldRequiredElement = document.querySelector(selectorError);
    const inputElement = document.getElementById(selectorInput);
    if (fieldRequiredElement && inputElement) {
      fieldRequiredElement.style.display = 'block';
      inputElement.style.border = '1px solid #ff1717';
    }
  };

  const sendOrderForWhatsapp = () => {
    const isNameInvalid = !nameAndSuername?.trim();

    const isDeliveryInvalid = isDelivery && !isRuralDelivery && (
        !streetAddress?.trim() ||
        !numberAddress?.trim() ||
        !localidadDirection?.trim()
    );

    if (isNameInvalid || isDeliveryInvalid) {
      if (isNameInvalid) {
        showFieldError('.errorNameAndSurname', 'nameAndSurname');
      }

      if (isDelivery && !isRuralDelivery) {
        if (!streetAddress?.trim()) {
          showFieldError('.errorStreetAddress', 'streetAddress');
        }
        if (!numberAddress?.trim()) {
          showFieldError('.errorNumberAddress', 'numberAddress');
        }
        if (!localidadDirection?.trim()) {
          showFieldError('.errorLocalidadDirection', 'localidadDirection');
        }
      }
      return;
    }

    if (isDelivery && !isRuralDelivery && config.verifyAddress && !isAddressValid) {
      showAlert({
        title: 'Error de dirección',
        text: 'La dirección ha cambiado o no ha sido validada. Por favor, validá la dirección antes de continuar.',
        icon: 'error',
      });
      return;
    }

    confirmarEnvioPedido();
  };


   const confirmarEnvioPedido = () => {
     Swal.fire({
       title: '¿Enviar pedido?',
       icon: 'question',
       showCancelButton: true,
       confirmButtonText: 'Enviar',
       confirmButtonColor: colorshop,
       cancelButtonText: 'Cancelar',
       reverseButtons: true,
     }).then((result) => {
       if (result.isConfirmed) {
         if (!config.status) {
           Swal.fire({
             title: 'Por el momento ya no tomamos más pedidos',
             text: "Gracias por tu comprensión, ¡te esperamos la próxima!",
             icon: 'error',
             showConfirmButton: true,
             allowOutsideClick: false,
             confirmButtonColor: colorshop,
             allowEscapeKey: false,
             allowEnterKey: false,
           }).then((result) => {
             if (result.isConfirmed) {
               const btnCloseModalCarrito = document.getElementById('btnCloseCarrito');
               btnCloseModalCarrito.click();
               const btnCloseModalDatosPersona = document.getElementById('btnCloseModalPersona');
               btnCloseModalDatosPersona.click();
             }
           });
         } else {
           let orderNumber = Date.now();
           createAndSendMessagePedidoWhatsapp(orderNumber);
           sendOrder(orderNumber);
         }
       }
     });
   };

   //si alguno de los delivery que tiene seteado esta activo
  function isDeliveryActive(config) {
    switch (config.deliveryType) {
      case 'COMMON':
        return config.deliveryCommon?.active || false;
      case 'CUSTOM':
        return config.deliveryCustom?.active || false;
      default:
        console.warn(`Tipo de delivery desconocido: ${config.deliveryType}`);
        return false;
    }
  }

  const sendOrder = (orderNumber) => {
    let order = {
      id: null,
      orderNumber: orderNumber,
      ownerOrder: nameAndSuername,
      totalAmount: 0,
      tableId: 1, //setear numero de mesa que selecciono el usuario
      orderType: OrderType.WHATSAP.value,
      sendOrderType: (isDelivery && isDeliveryActive(config)) ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
      address: streetAddress,
      numberAdress: numberAddress,
      floor: floorAddress,
      department: deptoAddress,
      provinceId: 20, //ver como mando el id de la provincia
      orderDetails: [],
      orderObservation: observationsPedido,
      deliveryObservation: observationsAddress,
      shippingPrice:getDeliveryPriceBase(config),
      ruralDeliveryActive: isRuralDelivery,
    }

    let products = JSON.parse(localStorage.getItem("productsPedido"));
    products.forEach((product) => {
      order.totalAmount += (product.cant * product.price);
      order.orderDetails.push(createOrderDetail(product));
    });

    order.totalAmount = order.totalAmount.toFixed(2);
    
    axios.post(process.env.REACT_APP_API_ORDER_ROUTE, order, {
      withCredentials: true
    })
    .then(response => {
        cleanPedido();
      })
      .catch(error => {
        console.log(error);
        
        Swal.fire({
          title: 'Error con el pedido:',
          text: error.message,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      });
  }

  const createOrderDetail = (product) => {
    return {
      id: null,
      orderId: null,
      productId: product.id,
      quantity: product.cant,
      unitaryPrice: Number(product.price),
      subTotal: Number(product.cant * product.price)
    };
  }

  const createAndSendMessagePedidoWhatsapp = (orderNumber) => {
    let totalPedido = 0;
    let mensaje = 'Hola ' + shop.businessName + ", quiero hacerte un pedido a nombre de " + nameAndSuername.trim() + ":";
    
    let products = JSON.parse(localStorage.getItem("productsPedido"));

    mensaje = mensaje + `\n`;
    mensaje = mensaje + `\n`;

    let productosTexto = "";

    products.forEach((product) => {
        productosTexto += `${product.cant}X ${product.name} ${formatPrice(product.price)}\n`;
        totalPedido += (product.price * product.cant);
    });

    if (isDelivery && isDeliveryActive(config)) {
      let priceEnvio = formatPrice(getDeliveryPriceBase(config));
      productosTexto += `ENVÍO ${formatPrice(priceEnvio)}\n`;
      totalPedido += parseFloat(priceEnvio);
    }
    mensaje += "Número de pedido: " + orderNumber;

    mensaje = mensaje + `\n`;
    mensaje = mensaje + `\n`;

    mensaje += `\Detalle del pedido:\n${productosTexto}`;

    mensaje = mensaje + `\n`;

    if (observationsPedido != null && observationsPedido != '') {

      mensaje = mensaje + "Observaciones del pedido: " + observationsPedido + ".";

      mensaje = mensaje + `\n`;
      mensaje = mensaje + `\n`;
    }

    mensaje = mensaje + "TOTAL: " + formatPrice(totalPedido);

    mensaje = mensaje + `\n`;
    mensaje = mensaje + `\n`;

    if(isDelivery && isDeliveryActive(config)){
      mensaje = mensaje + "Pedido con envio, al siguiente domicilio: " + streetAddress + " " + numberAddress + ".";
      if(floorAddress != '' && floorAddress != null){
        mensaje = mensaje + `\n`;
        mensaje = mensaje + "Piso: " + floorAddress + ".";
      }
      if(deptoAddress != '' && deptoAddress != null){
        mensaje = mensaje + `\n`;
        mensaje = mensaje + "Departamento: " + deptoAddress + ".";
      }
      if (observationsAddress != null && observationsAddress != '') {
        mensaje = mensaje + `\n`;
        mensaje = mensaje + "Observaciones de envío: " + observationsAddress + ".";
      }
    }else{
      mensaje = mensaje + "Retiro el pedido por el local.";
    }

    mensaje = mensaje + `\n`;
    mensaje = mensaje + `\n`;

    mensaje = mensaje + "¡Muchas gracias!"
    
    let numero = '+54';
    numero += shop.phone;
    const url = `https://api.whatsapp.com/send?phone=${numero}&text=${encodeURIComponent(mensaje)}`;

    window.open(url, '_blank');
    
    const btnCloseModalSendPedido = document.getElementById('btnCloseModalSendPedido');
    btnCloseModalSendPedido.click();
  }


  const cleanErrorField = (inputId, errorClass) => {
    const inputElement = document.getElementById(inputId);
    const fieldRequiredElement = document.querySelector(`.${errorClass}`);

    if (inputElement?.value) {
      fieldRequiredElement.style.display = 'none';
      inputElement.style.border = '';
    }
  };

  const cleanError = () => {
    const errorElements = [
      { error: '.errorStreetAddress', input: 'streetAddress' },
      { error: '.errorNumberAddress', input: 'numberAddress' },
      { error: '.errorLocalidadDirection', input: 'localidadDirection' }
    ];

    errorElements.forEach(({ error, input }) => {
      const fieldRequiredElement = document.querySelector(error);
      const inputElement = document.getElementById(input);

      if (fieldRequiredElement) fieldRequiredElement.style.display = 'none';
      if (inputElement) inputElement.style.border = '1px solid #ced4da';
    });
  };

  const cleanErrorSendPedidoWithoutDeliveryReset = () => {
    cleanError();
    setStreetAddress('');
    setNumberAddress('');
    setLocalidadDirection('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('');
  };


  const cleanErrorSendPedido = () => {
    let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
    if (fieldRequiredElement) {
        fieldRequiredElement.style.display = 'none';
    }
    
    let inputElement = document.getElementById('nameAndSurname');
    if (inputElement) {
        inputElement.style.border = '';
    }

    setNameAndSuername('');

    if (isDelivery) {
        fieldRequiredElement = document.querySelector('.errorAddressPedido');
        if (fieldRequiredElement) {
            fieldRequiredElement.style.display = 'none';
        }
        
        inputElement = document.getElementById('addressPedido');
        if (inputElement) {
            inputElement.style.border = '';
        }
    }
    setNumberAddress('');
    setLocalidadDirection('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('')
    setIsDelivery(false);
}


    return (
        <div className="carta-body">
          {(!config.status &&
              <div className="aviso">
                <p>Por el momento no se toman pedidos.</p>
              </div>
          )}
          <div className="banner" style={{backgroundColor: colorshop, display: "flex", justifyContent: "center", alignItems: "center"}}>
            {imageSrc != null && imageSrc != '' ? (
                <img src={imageSrc} alt="QReate" className='imgCartaBanner'/>
            ) : (
                <h1 className='nameShopCarta'>{nameShop}</h1>
            )}

          </div>

          <Carousel
              className="carrouselCarta"
              interval={3000}
              controls={false}
              indicators={true}
              pause="hover"
              wrap={true}
          >
            {imagesBanners.map((banner, index) => (
                banner.urlImage ? (
                    <Carousel.Item key={index}>
                      <img
                          className="d-block w-100"
                          src={banner.urlImage}
                          alt={`Banner ${banner.id}`}
                      />
                    </Carousel.Item>
                ) : null
            ))}
          </Carousel>

          <div className="categories-outer-container">
            <div className="categories-inner-container">
              <div className="categories">

                <div className="category" style={{marginLeft: '2%'}}>
                  <button className={`round-button ${allProducts == true ? 'selectedCategory' : ''}`} onClick={() => {
                    fillAllProducts();
                    setAllProducts(true);
                    setNameCategorySelected("Todos los productos");
                  }} style={{backgroundColor: colorshop}}>
                    <img src={iconoGenerico} alt="Icono" className='iconCategory'/>
                  </button>
                  <div className="category-name">Todos los productos</div>
                </div>

                {
                  categories.map((category, index) => (
                      <div className="category" key={index}>
                        <button
                            className={`round-button ${category.name === nameCategorySelected ? 'selectedCategory' : ''}`}
                            onClick={() => {
                              fillProductsByIdCategorySelected(category.id);
                              setNameCategorySelected(category.name);
                              setAllProducts(false)
                            }} style={{backgroundColor: colorshop}}>
                          <img src={category.urlIcon} alt="Icono" className='iconCategory'/>
                        </button>
                        <div className="category-name">{category.name}</div>
                      </div>
                  ))
                }
              </div>
            </div>
          </div>

          <div className='ms-3 me-3'>
            <div className="searchProduct">
              <div className="search">
                <input type="text" id='nameProdSearch' className="searchProdCarta" placeholder="Buscar producto"
                       style={{width: "100%"}} onChange={(e) => search(e.target.value)}/>
                <Search className="btnSearchCarta" size="35"/>
              </div>
            </div>

            <div className="mt-4 mb-4 products" style={{minHeight: '40vh'}}>
              {allProducts ? (
                  (searching) ? (
                      <>
                        <h6 className='titleCategory'>{allProductsText}</h6>
                        <br/>
                        {searchCategoriesWithProducts.map((category, index) => (
                            <div key={index}>
                              <h6 className='titleCategory'>{category.name}</h6>
                              {category.productDTOList.map((product, index) => (
                                  <div key={index} className={`product ms-3 me-3 ${product.image != null ? 'product-with-image' : ''}`}>
                                    {product.image != null ?
                                        <>
                                          <img src={product.image} alt="Imagen del producto"
                                               className="img-fluid imgProduct"/>
                                          <div className='bodyWithImage'>
                                            <p className='nameProd nameProdWithImage'>{product.name}</p>
                                            <p className='descProd descProdWithImage'>{product.description}</p>
                                            <div className='divPriceAndBotonera divPriceAndBotoneraWithImage'>

                                              {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                              {(config.priceActive) && <>
                                                  <p className='priceProd priceProdWithImage'>{formatPrice(product.price)}</p>
                                                  {(config.status) &&
                                                      <>
                                                        <div className='divBotonera divBotoneraWithImage'>
                                                          <button className='btn'><SubtractAlt size={20}
                                                                                               onClick={() => minusProdPedido(product.id)}/>
                                                          </button>
                                                          <p id='cantEnPedido cantEnPedidoWithImage'
                                                             style={{margin: '0'}}>{contProd(product.id)}</p>
                                                          <button className='btn'><AddAlt size={20}
                                                                                          style={{marginLeft: '5%'}}
                                                                                          onClick={() => addProdPedido(product)}/>
                                                          </button>
                                                        </div>
                                                      </>
                                                  }
                                              </>
                                              }



                                            </div>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <p className='nameProd'>{product.name}</p>
                                          <p className='descProd'>{product.description}</p>
                                          <div className='divPriceAndBotonera'>


                                            {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                            {(config.priceActive) && <>
                                                  <p className='priceProd'>{formatPrice(product.price)}</p>
                                                  {(config.status) &&
                                                      <>
                                                        <div className='divBotonera'>
                                                          <button className='btn'><SubtractAlt size={20}
                                                                                               onClick={() => minusProdPedido(product.id)}/>
                                                          </button>
                                                          <p id='cantEnPedido'
                                                             style={{margin: '0'}}>{contProd(product.id)}</p>
                                                          <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                            onClick={() => addProdPedido(product)}/>
                                                          </button>
                                                        </div>
                                                      </>
                                                  }
                                            </>
                                            }


                                          </div>
                                        </>
                                    }
                                  </div>
                              ))}
                              <br/>
                            </div>
                        ))}

                      </>
                  ) : (
                      <>
                        <h6 className='titleCategory ms-3 me-3'>{allProductsText}</h6>
                        <br/>
                        {categories.map((category, index) => (
                            <div key={index}>
                              <h6 className='titleCategory ms-3 me-3'>{category.name}</h6>
                              {category.productDTOList.map((product, index) => (
                                  <div key={index} className={`product ms-3 me-3 ${product.image != null ? 'product-with-image' : ''}`}>
                                    {product.image != null ?
                                        <>
                                          <img src={product.image} alt="Imagen del producto"
                                               className="img-fluid imgProduct"/>
                                          <div className='bodyWithImage'>
                                            <p className='nameProd nameProdWithImage'>{product.name}</p>
                                            <p className='descProd descProdWithImage'>{product.description}</p>
                                            <div className='divPriceAndBotonera divPriceAndBotoneraWithImage'>

                                              {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                              {(config.priceActive) && <>
                                                <p className='priceProd priceProdWithImage'>{formatPrice(product.price)}</p>
                                                {(config.status) &&
                                                    <>
                                                      <div className='divBotonera divBotoneraWithImage'>
                                                        <button className='btn'><SubtractAlt size={20}
                                                                                             onClick={() => minusProdPedido(product.id)}/>
                                                        </button>
                                                        <p id='cantEnPedido cantEnPedidoWithImage'
                                                           style={{margin: '0'}}>{contProd(product.id)}</p>
                                                        <button className='btn'><AddAlt size={20}
                                                                                        style={{marginLeft: '5%'}}
                                                                                        onClick={() => addProdPedido(product)}/>
                                                        </button>
                                                      </div>
                                                    </>
                                                }
                                              </>
                                              }


                                            </div>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <p className='nameProd'>{product.name}</p>
                                          <p className='descProd'>{product.description}</p>
                                          <div className='divPriceAndBotonera'>

                                            {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                            {(config.priceActive) && <>
                                                    <p className='priceProd'>{formatPrice(product.price)}</p>
                                                    {(config.status) &&
                                                        <>
                                                          <div className='divBotonera'>
                                                            <button className='btn'><SubtractAlt size={20}
                                                                                                 onClick={() => minusProdPedido(product.id)}/>
                                                            </button>
                                                            <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                                            <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                            onClick={() => addProdPedido(product)}/>
                                                            </button>
                                                          </div>
                                                        </>
                                                    }
                                            </>
                                            }
                                          </div>
                                        </>
                                    }

                                  </div>
                              ))}
                              <br/>
                            </div>
                        ))}

                      </>
                  )

              ) : (
                  <>
                    <h6 className='titleCategory'>{nameCategorySelected}</h6>
                    {(searching) ? (
                        searchProducts.map((product, index) => (
                            <div key={index} className={`product ${product.image != null ? 'product-with-image' : ''}`}>
                              {product.image != null ?
                                  <>
                                    <img src={product.image} alt="Imagen del producto"
                                         className="img-fluid imgProduct"/>
                                    <div className='bodyWithImage'>
                                      <p className='nameProd nameProdWithImage'>{product.name}</p>
                                      <p className='descProd descProdWithImage'>{product.description}</p>

                                      <div className='divPriceAndBotonera divPriceAndBotoneraWithImage'>

                                        {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                        {(config.priceActive) && <>
                                            <p className='priceProd priceProdWithImage'>{formatPrice(product.price)}</p>
                                            {(config.status) &&
                                                <>
                                                  <div className='divBotonera divBotoneraWithImage'>
                                                    <button className='btn'><SubtractAlt size={20}
                                                                                         onClick={() => minusProdPedido(product.id)}/>
                                                    </button>
                                                    <p id='cantEnPedido cantEnPedidoWithImage'
                                                       style={{margin: '0'}}>{contProd(product.id)}</p>
                                                    <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                    onClick={() => addProdPedido(product)}/>
                                                    </button>
                                                  </div>
                                                </>
                                            }
                                            </>
                                        }



                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <p className='nameProd'>{product.name}</p>
                                    <p className='descProd'>{product.description}</p>

                                    <div className='divPriceAndBotonera'>

                                      {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                      {(config.priceActive) && <>
                                            <p className='priceProd'>{formatPrice(product.price)}</p>
                                            {(config.status) &&
                                                <>
                                                  <div className='divBotonera'>
                                                    <button className='btn'><SubtractAlt size={20}
                                                                                         onClick={() => minusProdPedido(product.id)}/>
                                                    </button>
                                                    <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                                    <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                    onClick={() => addProdPedido(product)}/>
                                                    </button>
                                                  </div>
                                                </>
                                            }
                                      </>
                                      }

                                    </div>
                                  </>
                              }
                            </div>
                        ))
                    ) : (
                        productsForCategory.map((product, index) => (
                            <div key={index} className={`product ${product.image != null ? 'product-with-image' : ''}`}>
                              {product.image != null ?
                                  <>
                                    <img src={product.image} alt="Imagen del producto"
                                         className="img-fluid imgProduct"/>
                                    <div className='bodyWithImage'>
                                      <p className='nameProd nameProdWithImage'>{product.name}</p>
                                      <p className='descProd descProdWithImage'>{product.description}</p>

                                      <div className='divPriceAndBotonera divPriceAndBotoneraWithImage'>

                                        {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                        {(config.priceActive) && <>
                                        <p className='priceProd priceProdWithImage'>{formatPrice(product.price)}</p>
                                        {(config.status) &&
                                            <>
                                              <div className='divBotonera divBotoneraWithImage'>
                                                <button className='btn'><SubtractAlt size={20}
                                                                                     onClick={() => minusProdPedido(product.id)}/>
                                                </button>
                                                <p id='cantEnPedido cantEnPedidoWithImage'
                                                   style={{margin: '0'}}>{contProd(product.id)}</p>
                                                <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                onClick={() => addProdPedido(product)}/>
                                                </button>
                                              </div>
                                            </>
                                        }
                                        </>
                                        }


                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <p className='nameProd'>{product.name}</p>
                                    <p className='descProd'>{product.description}</p>

                                    <div className='divPriceAndBotonera'>

                                      {/*ACA SE HACE EL CONTROL CON LA VARIABLE isPriceActive*/}
                                      {(config.priceActive) && <>
                                          <p className='priceProd'>{formatPrice(product.price)}</p>
                                          {(config.status) &&
                                              <>
                                                <div className='divBotonera'>
                                                  <button className='btn'><SubtractAlt size={20}
                                                                                       onClick={() => minusProdPedido(product.id)}/>
                                                </button>
                                                <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                                <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                                onClick={() => addProdPedido(product)}/>
                                                </button>
                                              </div>
                                            </>
                                          }
                                      </>
                                      }

                                    </div>
                                  </>
                              }

                            </div>
                        )))
                    }
                  </>
              )}
            </div>
          </div>

          {((localStorage.getItem("productsPedido") != '[]') && (config.status)) && (
              <button id='btnCarrito' data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                      className="round-button-pedido"
                      onClick={() => {
                        setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")))
                      }}>
                <ShoppingCart size={30}/>
              </button>
          )}

          <div className="modal fade modal-lg" id="modalProductsPedido" data-bs-backdrop="static" data-bs-keyboard="false"
               tabIndex="-1" aria-labelledby="modalProductsPedidoLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modalPedido">
                  <h1 className="modal-title fs-5" id="modalProductsPedidoLabel"
                      className="titleModalPedido" style={{marginTop: '4%', marginLeft: '4%'}}>Tu pedido</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                          id="btnCloseCarrito"></button>
                </div>
                <div className="modal-body modalPedido">

                  {dataProductsPedidoModal.map((product, index) => (
                      <div key={index} className='productCarrito'>
                        <p className='nameProdPedido'>{product.name}</p>

                        <div className='divPriceAndBotoneraPedido'>
                          <p className='priceProdPedido'>{formatPrice(product.price)}</p>
                           {config.status &&
                              <>
                                <div className='divBotonera'>
                                  <button className='btn' style={{color: 'red'}}><TrashCan size={20}
                                                                                           onClick={() => deleteProductPedido(product.id)}/>
                                  </button>
                                  <button className='btn'><SubtractAlt size={20}
                                                                       onClick={() => minusProdPedido(product.id)}/>
                                  </button>
                                  <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                  <button className='btn'><AddAlt size={20} style={{marginLeft: '5%'}}
                                                                  onClick={() => addProdPedido(product)}/></button>
                                </div>
                              </>}
                        </div>
                        <p className='priceProdPedidoSubtotal'>Subtotal:
                          {formatPrice((product.price * product.cant).toFixed(2))}</p>
                      </div>
                  ))}
                  <br/>
                  <p className='priceProdTotalPedido'>TOTAL:
                    {formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}</p>
                  <br/>
                  <div style={{marginLeft: '5%'}}>
                    <label htmlFor="observations" className="col-form-label">Obvservaciones del pedido:</label>
                    <textarea rows="3" className="form-control observationsPedido" id="observationsPedido"
                              value={observationsPedido} onChange={(e) => {
                      setObservationsPedido(e.target.value);
                    }}/>
                  </div>
                </div>
                <div className="modal-footer modalPedido">
                  <button id="btnCloseModalAdd" type="button" className="btn btn-dark" data-bs-dismiss="modal"
                          onClick={() => cleanPedidoBorrador()}>Borrar pedido
                  </button>
                  <button id="btnSendPedidoForWhatsapp" type="button" className="btn"
                          data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalDataForPedido"
                          style={{backgroundColor: colorshop, color: '#ffffff'}}
                          disabled={dataProductsPedidoModal.length == 0 ? true : false}>Pedir por Whatsapp
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal-lg" id="modalDataForPedido" data-bs-backdrop="static" data-bs-keyboard="false"
               tabIndex="-1" aria-labelledby="modalDataForPedidoLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modalPedido">
                  <h1 className="modal-title fs-5" id="modalDataForPedidoLabel" className="titleModalDatosPedido">Datos para
                    finalizar tu pedido</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                          data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                          onClick={() => cleanErrorSendPedido()}></button>
                  <button id="btnCloseModalPersona" hidden={true} type="button" data-bs-dismiss="modal"
                          aria-label="Close" onClick={() => cleanErrorSendPedido()}></button>
                </div>
                <div className="modal-body modalPedido">

                  <label htmlFor="nameAndSurname" className="col-form-label">Nombre y apellido:</label>
                  <input type="text" className="form-control nameAndSuername" id="nameAndSurname"
                         value={nameAndSuername} onChange={(e) => {
                    setNameAndSuername(e.target.value);
                    cleanErrorField('nameAndSurname', 'errorNameAndSurname');
                  }}
                  />
                  <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                  <label htmlFor="isDelivery" className="col-form-label">Envío a domicilio:</label>
                  {(isDeliveryActive(config)) ? <>
                    <br/>
                    <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryTrue"
                          name="isDelivery"
                          value="true"
                          checked={isDelivery === true}
                          onChange={() => setIsDelivery(true)}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryTrue">
                        Sí
                      </label>
                    </div>
                    <br/>
                    <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryFalse"
                          name="isDelivery"
                          value="false"
                          checked={isDelivery === false}
                          onChange={() => {
                            setIsDelivery(false);
                            setStreetAddress('');
                            setNumberAddress('');
                            setLocalidadDirection('');
                            setFloorAddress('');
                            setDeptoAddress('');
                            setObservationsAddress('');
                          }}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryFalse">
                        No
                      </label>
                    </div>
                  </> : <>
                    <p style={{color: '#CB3234'}}>Por el momento no realizamos envíos, ¡retiralo en nuestro local!</p>
                    <div className="form-check form-check-inline">
                    <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryTrue"
                          name="isDelivery"
                          value="false"
                          disabled={true}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryTrue">
                        Sí
                      </label>
                    </div>
                    <br/>
                    <div className="form-check form-check-inline">
                    <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryFalse"
                          name="isDelivery"
                          value="true"
                          disabled={true}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryFalse">
                        No
                      </label>
                    </div>
                  </>}


                  {isDelivery && (
                      <>
                        <br/>
                        <p style={{color: "#959595"}}>
                          Valor del envío:{" "}
                          {googleLocalizationFeature
                              ? shippingPrice !== 0.0
                                  ? formatPrice(shippingPrice)
                                  : "Calculando..."
                              : formatPrice(config.deliveryCommon.priceBase)}
                        </p>

                        <label htmlFor="addressPedido" className="col-form-label">Dirección de entrega:</label>
                        <br/>

                        {/* Checkbox para Envío a dirección rural */}
                        {ruralSettingsFeatures?.status && config.ruralSettings && (
                            <div className="form-check form-check-inline">
                              <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="isRuralDelivery"
                                  name="isRuralDelivery"
                                  checked={isRuralDelivery}
                                  onChange={(e) => toggleRuralDelivery(e.target.checked)}
                              />
                              <label className="form-check-label" style={{marginTop: '0.7em'}}
                                     htmlFor="isRuralDelivery">
                                Envío a dirección rural
                              </label>
                            </div>
                        )}

                        <div className="row">
                          <div className="col-8">
                            <label htmlFor="streetAddress" className="col-form-label">Calle:</label>
                            <input
                                type="text"
                                className="form-control streetAddress"
                                id="streetAddress"
                                value={streetAddress}
                                onChange={(e) => {
                                  setStreetAddress(e.target.value);
                                  checkAddressChange();
                                  cleanErrorField('streetAddress', 'errorStreetAddress');
                                }}
                                disabled={!isDeliveryActive(config) || isRuralDelivery}
                            />
                            <div className="errorStreetAddress" id="errorStreetAddress">Campo obligatorio</div>
                          </div>

                          <div className="col">
                            <label htmlFor="numberAddress" className="col-form-label">Número:</label>
                            <input
                                type="number"
                                className="form-control numberAddress"
                                id="numberAddress"
                                value={numberAddress}
                                onChange={(e) => {
                                  setNumberAddress(e.target.value);
                                  checkAddressChange();
                                  cleanErrorField('numberAddress', 'errorNumberAddress');
                                }}
                                disabled={!isDeliveryActive(config) || isRuralDelivery}
                            />
                            <div className="errorNumberAddress" id="errorNumberAddress">Campo obligatorio</div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-8">
                            <label htmlFor="localidadDirection" className="col-form-label">Localidad:</label>
                            <input
                                type="text"
                                className="form-control localidadDirection"
                                id="localidadDirection"
                                value={localidadDirection}
                                onChange={(e) => {
                                  setLocalidadDirection(e.target.value);
                                  checkAddressChange();
                                  cleanErrorField('localidadDirection', 'errorLocalidadDirection');
                                }}
                                disabled={!isDeliveryActive(config) || isRuralDelivery}
                            />
                            <div className="errorLocalidadDirection" id="errorLocalidadDirection">Campo obligatorio
                            </div>
                          </div>

                          {config.verifyAddress && (
                              <div className="col">
                                <label htmlFor="validarDireccion" className="col-form-label"></label>
                                <br/><br/>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{backgroundColor: '#007bff', color: '#ffffff'}}
                                    onClick={() => validateAddress()}
                                    disabled={isRuralDelivery} // Bloquea si es rural
                                >
                                  Validar Dirección
                                </button>
                              </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col">
                            <label htmlFor="piso" className="col-form-label">Piso:</label>
                            <input
                                type="number"
                                className="form-control piso"
                                id="piso"
                                value={!isDeliveryActive(config) ? "" : floorAddress}
                                onChange={(e) => setFloorAddress(e.target.value)}
                                disabled={!isDeliveryActive(config) || isRuralDelivery}
                            />
                          </div>

                          <div className="col">
                            <label htmlFor="depto" className="col-form-label">Depto:</label>
                            <input
                                type="text"
                                className="form-control depto"
                                id="depto"
                                value={!isDeliveryActive(config) ? "" : deptoAddress}
                                onChange={(e) => setDeptoAddress(e.target.value)}
                                disabled={!isDeliveryActive(config) || isRuralDelivery}
                            />
                          </div>
                        </div>
                        <br/>

                        <label htmlFor="observations" className="col-form-label">Indicacion adicional del
                          destino:</label>
                        <textarea placeholder='Casa con rejas negras, sin timbre' rows="3"
                                  className="form-control observations" id="observations"
                                  value={!isDeliveryActive(config) ? "" : observationsAddress}
                                  onChange={(e) => {
                                    setObservationsAddress(e.target.value);
                                  }} disabled={!isDeliveryActive(config)}/>
                      </>


                  )}
                </div>

                <div className="modal-footer modalPedido">
                  <button id="btnCloseModalSendPedido" type="button" className="btn btn-secondary"
                          data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                          onClick={() => cleanErrorSendPedido()}>Volver
                  </button>
                  <button type="button" className="btn"
                          style={{backgroundColor: colorshop, color: '#ffffff'}}
                          onClick={() => sendOrderForWhatsapp()}>Enviar pedido
                  </button>
                </div>
              </div>
            </div>
          </div>
          {

              <Link to="/estadoDelPedido" className="btn miPedidoButton m-4" style={{backgroundColor: colorshop}}>
                Ver Mi Pedido
              </Link>
          }

          <div className='footer' style={{backgroundColor: colorshop}}>
            <p>Seguínos en nuestras redes</p>
            <div>
              {instagram != '' && <a className="btn" target="_blank" href={instagram}><LogoInstagram size="24"/></a>}
              {facebook != '' && <a className="btn" target="_blank" href={facebook}><LogoFacebook size="24"/></a>}
              {twitter != '' && <a className="btn" target="_blank" href={twitter}><LogoTwitter size="24"/></a>}
            </div>
            <img src={iconoFooter} alt="Icono"/>
          </div>

        </div>
    );


}